import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles/App.scss";
import CommonContainer from "./Common/CommonContainer";
import Awards from "./Pages/Awards";
import CodeForm from "./Pages/CodeForm";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Mechanism from "./Pages/Mechanism";
import Rules from "./Pages/Rules";
import Winners from "./Pages/Winners";
import BeforeStart from "./Pages/BeforeStart";
import AfterEnd from "./Pages/AfterEnd";
import CommonContainer2 from "./Common/CommonContainer2";
import CommonContainer3 from "./Common/CommonContainer3";
import CommonContainer4 from "./Common/CommonContainer4";

const isBeforeStart = () => {
  const currentDate = new Date();
  const targetDate = new Date("2024-06-18"); // 17. jul 2023. godine
  return currentDate < targetDate;
};

const isAfterEnd = () => {
  const currentDate = new Date();
  const targetDate = new Date("2025-09-20"); // 28. avgust 2023. godine
  return currentDate > targetDate;
};

const App: React.FC = () => {
  let startRoute = isBeforeStart() ? "/odbrojavanje" : "/";
  let endRoute = isAfterEnd() ? "/kraj" : "/";

  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Routes>
          {isBeforeStart() && (
            <>
              <Route
                element={
                  <CommonContainer>
                    <BeforeStart />
                  </CommonContainer>
                }
                path="/"
              />

              <Route
                element={
                  <CommonContainer>
                    <BeforeStart />
                  </CommonContainer>
                }
                path="/prijava"
              />
            </>
          )}
          {isAfterEnd() && (
            <Route
              element={
                <CommonContainer>
                  <AfterEnd />
                </CommonContainer>
              }
              path="/"
            />
          )}
          {!isBeforeStart() && !isAfterEnd() && (
            <>
              <Route
                element={
                  <CommonContainer>
                    <Awards />
                  </CommonContainer>
                }
                path="/nagrade"
              />
              <Route
                element={
                  <CommonContainer>
                    <CodeForm />
                  </CommonContainer>
                }
                path="/prijava"
              />
              <Route
                element={
                  <CommonContainer>
                    <Contact />
                  </CommonContainer>
                }
                path="/kontakt"
              />
              <Route
                element={
                  <CommonContainer>
                    <Mechanism />
                  </CommonContainer>
                }
                path="/mehanizam"
              />
              <Route
                element={
                  <CommonContainer>
                    <Rules />
                  </CommonContainer>
                }
                path="/pravila"
              />
              <Route
                element={
                  <CommonContainer>
                    <Winners />
                  </CommonContainer>
                }
                path="/dobitnici"
              />
              <Route
                element={
                  <CommonContainer>
                    <Home />
                  </CommonContainer>
                }
                path="/"
              />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
