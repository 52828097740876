import React from "react";
import { Col, Row } from "react-bootstrap";
import BottomContent from "../Common/BottomContent";
import LeftContent from "../Common/LeftContent";
import Logo from "../Common/Logo";
import Navigation from "../Common/Navigation";
import RightContent from "../Common/RightContent";
import NavButtons from "../Common/NavButtons";
import NavButtonsMobile from "../Common/NavButtonsMobile";

const Mechanism: React.FC = () => {
  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };

  <div className="common-center"></div>;

  return (
    <div className="mechanism">
      <div className="desktop-content">
        <div className="common-container">
          <Logo />
          <NavButtons />
          <div className="common-center">
            <div className="mechanism-left-content">
              <div className="header-text">
                u fudbalu
                <br />
                uživaj, nagrade
                <br />
                osvajaj!
              </div>
              <div className="mechanism-img">
                <img src="../mechanism-content.png" alt="Home" />
              </div>
            </div>
          </div>
          <BottomContent />
        </div>
      </div>
      <div className="mobile-content">
        <div className="common-container">
          <div className="header-mobile">
            <NavButtonsMobile />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mechanism;
