import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const PhoneAndCodeAPI = async (
  phone: string,
  ikof: string,
  firstAndLastName: string,
  email: string,
  token: any
): Promise<number | boolean> => {
  try {
    const entry = ikof;
    const config: AxiosRequestConfig = {
      method: "POST",
      // url: `${process.env.REACT_APP_HOST}/ultra3/add-entry`, http://ultra-cib.malihetz.complete-inbox.com
      url: `https://cceurocup2024.me/api/ultra/add-entry-cceurocup`,
      // url:`http://loaclhost:6002/api/ultra/add-entry-cceurocup`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        entry,
        identity: phone,
        firstAndLastName,
        email,
        recaptchaToken: token,
      },
    };

    console.log(config.data)
    const response: AxiosResponse = await axios(config);

    const { data } = response;
    const { code: responseCode } = data;

    return responseCode;
  } catch (error) {
    return false;
  }
};

export default PhoneAndCodeAPI;
