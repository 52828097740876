import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BottomContent from "../Common/BottomContent";
import LeftContent from "../Common/LeftContent";
import Logo from "../Common/Logo";
import Navigation from "../Common/Navigation";
import RightContent from "../Common/RightContent";
import NavButtons from "../Common/NavButtons";
import NavButtonsMobile from "../Common/NavButtonsMobile";

const Contact: React.FC = () => {
  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };

  return (
    <div className="contact">
      <div className="desktop-content">
        <div className="common-container">
          <Logo />
          <NavButtons />
          <Container className="right-header2">
            <Row>
              <Col xs={12} md={12} className="right-text-column">
                <h1 className="header-right-text-code-form">
                  <span className="line">u fudbalu uživaj,</span>
                  <br />
                  <span className="line">nagrade osvajaj</span>
                  <br />
                </h1>
                {/* <div className="right-text">
                    <h2>U FUBALU UZIVAJ NAGRADE OSVAJAJ</h2>
                  </div> */}
              </Col>
            </Row>
          </Container>
          <div className="common-center">
            <Container className="dynamic-container">
              {/* <Row>
                <Col xs={12} md={12} className="text-column">
                  <h1 className="header-text-contact">
                    <span className="line">javi se</span>
                    <br />
                    <span className="line">call centar!</span>
                    <br />
                    <span className="line">+38162395361</span>
                    <br />
                    <span className="line header-text-contact-viber">
                      (Kontaktirajte nas putem Vibera)
                    </span>
                  </h1>
                </Col>
              </Row> */}
            </Container>
            <Container className="dynamic-container">
              <Row>
                <Col xs={12} md={12} className="image-column">
                  <br />
                  <br />
                  <img src="../call-centar-posle2.png" alt="Awards" />
                  <br />
                  <br />
                </Col>
              </Row>
            </Container>
            <Container className="dynamic-container-email">
              {/* <Row>
                <Col xs={12} md={12} className="text-column">
                  <h1 className="header-text-contact-email">
                    <span className="line">E-mail</span>
                    <br />
                    <span className="line">info@cceurocup2024.me</span>
                  </h1>
                </Col>
              </Row> */}
            </Container>

            {/* <div className="call-centar">
              <img src="../call-centar1.png" alt="Call centar" />
            </div> */}
          </div>
          {/* <BottomContent /> */}
        </div>
      </div>
      <div className="mobile-content">
        <div className="common-container">
          <div className="header-mobile">
            <NavButtonsMobile />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
