import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";

const NavButtonsMobile: React.FC = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const renderNavButton = (to: string, title: string) => {
    const isActive = location.pathname === to;
    return (
      <Col
        md={2}
        className={`nav-button-mobile ${isActive ? "active-link" : ""}`}
      >
        <Link to={to}>{title}</Link>
      </Col>
    );
  };

  return (
    <div>
      <div className="logo-mobile">
        <img src="../CocaColaLogo.png" alt="Home" />
      </div>
      <div className="nav-buttons-mobile">
        <div className="icon" onClick={(e) => setIsOpen(!isOpen)}>
          {!isOpen && (
            <div className="menu-mobile-open-div">
              <img src="../menu-mobile-open.png" />
            </div>
          )}
          {isOpen && (
            <div className="menu-mobile-close-div">
              <img src="../menu-mobile-close.png" />
            </div>
          )}
        </div>
      </div>
      <div>
        {isOpen && (
          <div className="menu-mobile-open">
            <ul className="menu-ul text-right">
              <li>{renderNavButton("/", "POČETNA STRANICA")}</li>
              <li>{renderNavButton("/", "PRIJAVI SE")}</li>
              <li>{renderNavButton("/", "MEHANIZAM")}</li>
              <li>{renderNavButton("/nagrade", "NAGRADE")}</li>
              <li>{renderNavButton("/dobitnici", "DOBITNICI")}</li>
              <li>{renderNavButton("/pravila", "PRAVILA")}</li>
              <li>{renderNavButton("/kontakt", "KONTAKT")}</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavButtonsMobile;
