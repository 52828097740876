import React from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import "../styles/PopUp.css";

interface PopUpProps {
  message: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const PopUp: React.FC<PopUpProps> = (props) => {
  return (
    <React.Fragment>
      <Button type="submit" className="sign-up" style={{ color: "white" }}>
        PRIJAVI SE
      </Button>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="popUp-bg">
          <DialogActions className="d-flex justify-content-start m-4">
            <button onClick={() => props.setOpen(false)} className="popUp-btn">
              <img src="/exit-icon.png" alt="X" className="popUp-img" />
            </button>
          </DialogActions>
          <DialogTitle id="responsive-dialog-title" style={{ padding: 0 }}>
            <div>
              <p className="popUp-text">{props.message}</p>
            </div>
          </DialogTitle>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default PopUp;

// import React from "react";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import "../styles/PopUp.css";
// import { Button, Modal } from "react-bootstrap";

// interface PopUpProps {
//   message: string;
//   open: boolean;
//   setOpen: (open: boolean) => void;
// }

// const PopUp: React.FC<PopUpProps> = (props) => {
//   return (
//     <React.Fragment>
//       <Button type="submit" className="sign-up" style={{ color: "white" }}>
//         PRIJAVI SE
//       </Button>
//       <Modal
//         open={props.open}
//         onClose={() => props.setOpen(false)}
//         aria-labelledby="responsive-dialog-title"
//       >
//         <div className="popUp-bg">
//           <DialogActions className="d-flex justify-content-start m-4">
//             <button onClick={() => props.setOpen(false)} className="popUp-btn">
//               <img src="/exit_icon.png" alt="X" className="popUp-img" />
//             </button>
//           </DialogActions>
//           <DialogTitle id="responsive-dialog-title" style={{ padding: 0 }}>
//             <div>
//               <p className="popUp-text"> {props.message}</p>
//             </div>
//           </DialogTitle>
//         </div>
//       </Modal>
//     </React.Fragment>
//   );
// };

// export default PopUp;
