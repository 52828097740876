import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Col, Container, Row } from "react-bootstrap";
import BottomContent from "../Common/BottomContent";
import LeftContent from "../Common/LeftContent";
import Logo from "../Common/Logo";
import Navigation from "../Common/Navigation";
import PopUp from "../Common/PopUp";
import RightContent from "../Common/RightContent";
import PhoneAndCodeAPI from "../helpers/PhoneAndCodeAPI";
import validation from "../helpers/validation";
import { url } from "inspector";
import NavButtons from "../Common/NavButtons";
import NavButtonsMobile from "../Common/NavButtonsMobile";

const CodeForm: React.FC = () => {
  const [phone, setPhone] = useState("");
  const [Code, setCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [acceptPrivacy, setAcceptPrivacy] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const reRef = useRef<ReCAPTCHA | null>(null);
  // const recaptchaSiteKey = process.env.REACT_APP_NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage("");

    // if (!validation(phone, Code, firstName, email)) {
    //   setErrorMessage(
    //     "VAŠA PRIJAVA JE NEUSPEŠNA. PROVERITE PODATKE KOJE STE UNELI ILI NAS POZOVITE."
    //   );
    //   setOpen(true);
    //   return;
    // }
    if (!acceptPrivacy) {
      setErrorMessage(
        "MOLIMO DA SE SAGLASITE SA POLITIKOM PRIVATNOSTI KAKO BISTE MOGLI DA UČESTVUJETE."
      );
      setOpen(true);
      return;
    }

    // const token = await reRef.current?.executeAsync();
    // const token = await reRef.current?.executeAsync();
    const token = "6LeSRygnAAAAAG2JDpGldjusIALOAVRuBms1hkhG";
    const statusCode = await PhoneAndCodeAPI(
      phone,
      Code,
      firstName,
      email,
      token
    );
    if (statusCode !== 201) {
      if (statusCode === 500) {
        setErrorMessage(
          "OVA PRIJAVA JE VEĆ ISKORIŠĆENA. U TOKU NAGRADNE IGRE JEDNA ISTA PRIJAVA SE NE MOŽE KORISTITI VIŠE PUTA. ZA VIŠE INFORMACIJA POZOVI 062 395361. Hvala!"
        );
        setOpen(true);
      } else {
        setErrorMessage(
          "TVOJA PRIJAVA NIJE PRIHVAĆENA. PROVERI UNOS PODATAKA I POKUŠAJ PONOVO ILI POZOVI 062 395361. HVALA ŠTO UČESTVUJEŠ!"
        );
        setOpen(true);
      }
      return;
    }

    setErrorMessage(
      "TVOJA PRIJAVA JE PRIHVAĆENA. SAČUVAJ RAČUN DO PREUZIMANJA NAGRADE U SLUČAJU DOBITKA! HVALA!"
    );
    setOpen(true);

    setCode("");
    setPhone("");
    setFirstName("");
    setEmail("");
    // reRef.current?.reset();
  };

  return (
    <div className="code-form">
      <div className="desktop-content">
        <div className="common-container">
          <Logo />
          <NavButtons />
          <Container className="right-header2">
            <Row>
              <Col xs={12} md={12} className="right-text-column">
                <h1 className="header-right-text-code-form">
                  <span className="line">u fudbalu uživaj,</span>
                  <br />
                  <span className="line">nagrade osvajaj</span>
                  <br />
                </h1>
                {/* <div className="right-text">
                    <h2>U FUBALU UZIVAJ NAGRADE OSVAJAJ</h2>
                  </div> */}
              </Col>
            </Row>
          </Container>
          <div className="common-center">
            <Container className="dynamic-container">
              <Row>
                <Col xs={12} md={3} className="image-column">
                  <img
                    src="../hand-left.png"
                    alt="Slika 1"
                    className="img-fluid"
                  />
                </Col>
                <Col xs={12} md={6} className="text-column">
                  <h1 className="header-text-code-form">
                    <span className="line">ajmo, ajde</span>
                    <br />
                    <span className="line">unesi podatke</span>
                    <br />
                  </h1>
                </Col>
                <Col xs={12} md={3} className="image-column">
                  <img
                    src="hand-right.png"
                    alt="Slika 2"
                    className="img-fluid"
                  />
                </Col>
              </Row>
            </Container>
            <div className="red">
              <form
                onSubmit={handleSubmit}
                className="d-block"
                autoComplete="off"
              >
                <div className="d-block">
                  <div>
                    <label htmlFor="firstName" className="label">
                      {renderText("Ime i prezime", "custom-label")}
                    </label>
                  </div>
                  <div className="form_input1">
                    <input
                      id="firstName"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="form-input custom-input"
                      placeholder="unesi"
                    />
                  </div>
                </div>
                <div className="d-block">
                  <div>
                    <label htmlFor="phone" className="label">
                      {renderText("Broj mobilnog telefona", "custom-label")}
                    </label>
                  </div>
                  <div className="form_input1">
                    <input
                      id="phone"
                      type="text"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="form-input custom-input"
                      placeholder="unesi"
                    />
                  </div>
                </div>
                <div className="d-block">
                  <div>
                    <label htmlFor="email" className="label">
                      {renderText("E-mail adresa", "custom-label")}
                    </label>
                  </div>
                  <div className="form_input1">
                    <input
                      id="email"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-input custom-input"
                      placeholder="unesi"
                    />
                  </div>
                </div>
                <div className="d-block mt-1 mb-2">
                  <div>
                    <label htmlFor="Code" className="label">
                      {renderText("IKOF broj računa", "custom-label")}
                    </label>
                  </div>
                  <div>
                    <input
                      id="Code"
                      type="text"
                      value={Code}
                      onChange={(e) => setCode(e.target.value)}
                      className="form-input custom-input"
                      placeholder="unesi"
                    />
                  </div>
                </div>
                <div className="privacy">
                  <input
                    type="checkbox"
                    onChange={() => setAcceptPrivacy(!acceptPrivacy)}
                  />
                  <a
                    href="/Zaštita-podataka-o-ličnosti.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="slazemse"
                  >
                    Slažem se sa politikom privatnosti
                  </a>
                </div>
                <PopUp open={open} setOpen={setOpen} message={errorMessage} />
              </form>
              <Container className="dynamic-container-bottom">
                <Row>
                  <br />
                  <Col xs={12} md={4} className="image-column">
                    <img
                      src="../sacuvaj-racun1.png"
                      alt="Slika 1"
                      className="img-fluid"
                    />
                  </Col>
                  <Col xs={12} md={8} className="text-column-bottom">
                    <h1 className="bottom-text-code-form">
                      <span className="line">obavezno sačuvaj</span>
                      <br />
                      <span className="line">fiskalni račun</span>
                      <br />
                    </h1>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <BottomContent />
        </div>
      </div>
      <div className="mobile-content">
        <div className="common-container">
          <div className="header-mobile">
            <NavButtonsMobile />
          </div>
          <div className="common-center">
            <div className="red">
              <form
                onSubmit={handleSubmit}
                className="d-block"
                autoComplete="off"
              >
                <div className="d-block mt-1 mb-2">
                  <div>
                    <label htmlFor="firstName" className="label">
                      {renderText("Ime i prezime", "custom-label")}
                    </label>
                  </div>
                  <div>
                    <input
                      id="firstName"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="form-input custom-input"
                      placeholder="unesi"
                    />
                  </div>
                </div>
                <div className="d-block mt-1 mb-2">
                  <div>
                    <label htmlFor="phone" className="label">
                      {renderText("Broj mobilnog telefona", "custom-label")}
                    </label>
                  </div>
                  <div>
                    <input
                      id="phone"
                      type="text"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="form-input custom-input"
                      placeholder="unesi"
                    />
                  </div>
                </div>
                <div className="d-block mt-1 mb-2">
                  <div>
                    <label htmlFor="email" className="label">
                      {renderText("E-mail adresa", "custom-label")}
                    </label>
                  </div>
                  <div>
                    <input
                      id="email"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-input custom-input"
                      placeholder="unesi"
                    />
                  </div>
                </div>
                <div className="d-block mt-1 mb-2">
                  <div>
                    <label htmlFor="Code" className="label">
                      {renderText("IKOF broj računa", "custom-label")}
                    </label>
                  </div>
                  <div>
                    <input
                      id="Code"
                      type="text"
                      value={Code}
                      onChange={(e) => setCode(e.target.value)}
                      className="form-input custom-input"
                      placeholder="unesi"
                    />
                  </div>
                </div>
                <div className="privacy">
                  <input
                    type="checkbox"
                    onChange={() => setAcceptPrivacy(!acceptPrivacy)}
                  />
                  <a
                    href="/Zaštita-podataka-o-ličnosti.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="slazemse"
                  >
                    Slažem se sa politikom privatnosti
                  </a>
                </div>
                <PopUp open={open} setOpen={setOpen} message={errorMessage} />
              </form>
              {/* <Container className="dynamic-container-bottom">
                <Row>
                  <Col xs={12} md={4} className="image-column">
                    <img
                      src="../sacuvaj-racun1.png"
                      alt="Slika 1"
                      className="img-fluid"
                    />
                  </Col>
                  <Col xs={12} md={8} className="text-column">
                    <h1 className="header-text-code-form">
                      <span className="line">obavezno sačuvaj</span>
                      <br />
                      <span className="line">fiskalni račun</span>
                      <br />
                    </h1>
                  </Col>
                </Row>
              </Container> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeForm;
