import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LeftContent from "../Common/LeftContent";
import RightContent from "../Common/RightContent";
import Navigation from "../Common/Navigation";
import BottomContent from "../Common/BottomContent";
import Logo from "../Common/Logo";
import NavButtons from "../Common/NavButtons";
import NavButtonsMobile from "../Common/NavButtonsMobile";

const Awards: React.FC = () => {
  const renderAwardImage = (src: string, alt: string) => {
    return (
      <Col xs={12} sm={12} md={6}>
        <img className="award-image" src={src} alt={alt} />
      </Col>
    );
  };
  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };

  return (
    <div className="awards">
      <div className="desktop-content">
        <div className="common-container">
          <Logo />
          <NavButtons />
          <Container className="right-header2">
            <Row>
              <Col xs={12} md={12} className="right-text-column">
                <h1 className="header-right-text-code-form">
                  <span className="line">u fudbalu uživaj,</span>
                  <br />
                  <span className="line">nagrade osvajaj</span>
                  <br />
                </h1>
                {/* <div className="right-text">
                    <h2>U FUBALU UZIVAJ NAGRADE OSVAJAJ</h2>
                  </div> */}
              </Col>
            </Row>
          </Container>
          <div className="common-center">
            <Container className="dynamic-container dynamic-container-align">
              <Row>
                <Col xs={12} md={12} className="text-column">
                  <h1 className="header-text-awards">
                    <span className="line">nedeljna nagrada</span>
                    <br />
                    <br />
                  </h1>
                </Col>
              </Row>
            </Container>
            <Container className="dynamic-container">
              <Row>
                <Col xs={12} md={12} className="image-column">
                  <img src="../xbox.png" alt="Awards" />
                </Col>
              </Row>
            </Container>
            <Container className="dynamic-container">
              <Row>
                <Col xs={12} md={12} className="text-column">
                  <h1 className="header-text-awards">
                    <br />
                    <span className="line">dnevne nagrade</span>
                    <br />
                    <br />
                  </h1>
                </Col>
              </Row>
            </Container>
            <Container className="dynamic-container">
              <Row>
                <Col xs={12} md={4} className="image-column">
                  <img
                    src="../ranac1.png"
                    alt="Slika 1"
                    className="img-fluid"
                  />
                </Col>
                <Col xs={12} md={4} className="image-column">
                  <img
                    src="../set-casa2.png"
                    alt="Slika 1"
                    className="img-fluid"
                  />
                </Col>
                <Col xs={12} md={4} className="image-column">
                  <img src="glovo3.png" alt="Slika 2" className="img-fluid" />
                </Col>
              </Row>
            </Container>
            <br />
            <br />
            <Container className="dynamic-container">
              <Row>
                <Col xs={12} md={6} className="image-column">
                  <img
                    src="../lopta1.png"
                    alt="Slika 1"
                    className="img-fluid"
                  />
                </Col>
                <Col xs={12} md={6} className="image-column">
                  <img
                    src="aparat2.png"
                    alt="Slika 2"
                    className="img-fluid"
                    style={{ marginTop: "6%" }}
                  />
                </Col>
              </Row>
            </Container>
            <Container className="dynamic-container">
              <Row>
                <Col xs={12} md={12} className="text-column">
                  <h1 className="header-text-awards-bottom">
                    <br />
                    <br />
                    <span className="line">
                      Fotografije proizvoda su ilustrativnog karaktera
                    </span>
                    <br />
                  </h1>
                </Col>
              </Row>
            </Container>
            {/* <div className="box">
              <div className="header-text-awards ">nedeljna nagrada</div>
            </div> */}
          </div>
          {/* <BottomContent /> */}
        </div>
      </div>
      <div className="mobile-content">
        <div className="common-container">
          <div className="header-mobile">
            <NavButtonsMobile />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awards;
