import React from "react";
import { Col, Row } from "react-bootstrap";
import LeftContent from "../Common/LeftContent";
import RightContent from "../Common/RightContent";
import Navigation from "../Common/Navigation";
import BottomContent from "../Common/BottomContent";

const BeforeStart: React.FC = () => {
  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };

  return (
    <div className="beforeStart">
      <div className="desktop-content"></div>
      <div className="mobile-content"></div>
    </div>
  );
};

export default BeforeStart;
