import React from "react";
import LeftContent from "../Common/LeftContent";
import RightContent from "../Common/RightContent";
import { Col, Row } from "react-bootstrap";
import Logo from "../Common/Logo";
import Navigation from "../Common/Navigation";
import BottomContent from "../Common/BottomContent";
import NavButtons from "../Common/NavButtons";
import NavButtonsMobile from "../Common/NavButtonsMobile";

const Rules: React.FC = () => {
  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };
  const handleClick = () => {
    window.open("/Pravila nagradna igra Voli Euro 2024.pdf");
  };

  return (
    <div className="rules">
      <div className="desktop-content">
        <div className="common-container">
          <Logo />
          <NavButtons />
          <div className="common-center">
            <Row>
              <Col lg={2} />
              <Col lg={8} className="custom-content">
                {renderText(
                  "Ova Pravila stupaju na snagu danom njihovog donošenja,",
                  "custom-text"
                )}
                {renderText(
                  "a prema trećim licima danom javnog objavljivanja u dnevnom listu,",
                  "custom-text"
                )}
                {renderText(
                  "a nakon dobijanja saglasnosti Ministra finansija.",
                  "custom-text"
                )}
                <br />
                <div className="button-container">
                  <button onClick={handleClick} className="custom-button">
                    {renderText("Pravila", "button-text")}
                  </button>
                </div>
              </Col>
              <Col lg={2} />
            </Row>
          </div>
          {/* <BottomContent /> */}
        </div>
      </div>
      <div className="mobile-content">
        <div className="common-container">
          <div className="header-mobile">
            <NavButtonsMobile />
          </div>
          <div className="common-center">
            <div className="custom-text">
              Ova Pravila stupaju na snagu danom njihovog donošenja, a prema
              trećim licima danom javnog objavljivanja u dnevnom listu, a nakon
              dobijanja saglasnosti Ministra finansija.
            </div>
            <div className="button-container">
              <button onClick={handleClick} className="custom-button">
                {renderText("Pravila", "button-text")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rules;
