import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LeftContent from "../Common/LeftContent";
import RightContent from "../Common/RightContent";
import Navigation from "../Common/Navigation";
import BottomContent from "../Common/BottomContent";
import Logo from "../Common/Logo";
import NavButtons from "../Common/NavButtons";
import NavButtonsMobile from "../Common/NavButtonsMobile";

const Home: React.FC = () => {
  const renderAwardImage = (src: string, alt: string) => {
    return (
      <Col xs={12} sm={12} md={6}>
        <img className="award-image" src={src} alt={alt} />
      </Col>
    );
  };
  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };

  return (
    <div className="home">
      <div className="desktop-content">
        <div className="common-container">
          <Logo />
          <NavButtons />
          <div className="common-center">
            <div className="home-left-content">
              {/* <div className="header-text">
                u fudbalu
                <br />
                uživaj, nagrade
                <br />
                osvajaj!
              </div> */}
              <Container className="dynamic-container-text">
                <Row>
                  <Col xs={12} md={12} className="text-column">
                    <h1 className="header-text">
                      <span className="line">Nagradna igra je završene!</span>
                      <br />
                      <span className="line">
                        Ukoliko imate dodatnih pitanja <br /> kontaktirajte nas
                        putem e-mail
                        <br /> adrese:
                      </span>
                      <br />
                      <a
                        href="mailto:info@cceurocup2024.me"
                        className="line"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        info@cceurocup2024.me
                      </a>
                      <br />
                    </h1>
                  </Col>
                </Row>
              </Container>
              {/* <Container className="dynamic-container">
                <Row>
                  <Col xs={12} md={12} className="image-column">
                    <img
                      src="../home-flasice-coca-cola-nagrade.png"
                      alt="Awards"
                    />
                  </Col>
                </Row>
              </Container> */}
              {/* <div className="img-home-flasice-coca-cola-nagrade">
                <img src="../home-flasice-coca-cola-nagrade.png" alt="Home" />
              </div> */}
            </div>
          </div>
          {/* <BottomContent /> */}
        </div>
      </div>
      <div>
        <div className="mobile-home">
          <div className="mobile-content">
            <div className="common-container">
              <div className="header-mobile">
                <NavButtonsMobile />
              </div>
              <div className="common-center">
                <div className="custom-text">
                  Nagradna igra je završene!
                  <br />
                  Ukoliko imate dodatnih pitanja
                  <br />
                  kontaktirajte nas putem e-mail
                  <br />
                  adrese:
                  <br />
                  <a
                    href="mailto:info@cceurocup2024.me"
                    className="line"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    info@cceurocup2024.me
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // return (
  //   //div treba da ima center klasu
  //   <div>
  //     <img className="home" src="../pozadina-home-ista.png" alt="Home" />
  //   </div>
  // );

  // return (
  //   <div className="home">
  //     <div className="desktop-content">
  //       <LeftContent />
  //       <div className="middle-content">
  //         {renderText(
  //           "U FUDBALU UŽIVAJ,",
  //           "text-white-big lh-1 fs-1 text-left"
  //         )}
  //         {renderText(
  //           "NAGRADE OSVAJAJ!!",
  //           "text-white-big lh-1 fs-1 text-left"
  //         )}
  //         {renderText(
  //           "1500 NAGRADA! ALE, ALE!",
  //           "text-white-big lh-1 fs-1 text-left-indent"
  //         )}
  //         <br />
  //         <Row className="">
  //           <Col lg={6}>
  //             <Link to="/prijava">
  //               <Button className="sign-up">PRIJAVI SE</Button>
  //             </Link>
  //           </Col>
  //         </Row>
  //         {/* <Row>
  //           <Col lg={3} />
  //           <Col lg={6}>
  //             <Link to="/prijava">
  //               <Button className="sign-up">PRIJAVI SE</Button>
  //             </Link>
  //           </Col>
  //           <Col lg={3} />
  //         </Row> */}
  //         <br />
  //         {/* {renderText("NAGRADNA IGRA", "text-white-small lh-0 fw-500")}
  //         {renderText(
  //           "TRAJE OD 14.06. DO 13.07.2024.",
  //           "text-white-small lh-0 fw-500"
  //         )} */}
  //       </div>
  //       <div className="bottom-text">
  //         {renderText(
  //           "NAGRADNA IGRA TRAJE OD 14.06. DO 13.07.2024.",
  //           "text-white-small fs-14 lh-0 fw-500"
  //         )}
  //       </div>
  //       {/* <RightContent /> */}
  //     </div>
  //     <div className="mobile-content">
  //       <Logo />
  //       <Navigation />
  //       <div className="middle-content">
  //         <Row>
  //           <Col xs={3} />
  //           <Col xs={6}>
  //             <img
  //               className="home-bogdan-figure-1"
  //               src="../U0015BogdanovicFigure1v1.png"
  //               alt="Logo"
  //             />
  //           </Col>
  //           <Col xs={3} />
  //         </Row>
  //         {renderText("U FUDBALU UŽIVAJ", "text-white-big fs-55")}
  //         {renderText("NAGRADE OSVAJAJ!!", "text-ultra-green-big fs-35 lh-0")}
  //         {renderText("1500 NAGRADA! ALE, ALE!", "text-white-big fs-55 lh-1")}
  //         <br />
  //         <Row>
  //           <Col xs={3} />
  //           <Col xs={6}>
  //             <Link to="/prijava">
  //               <Button className="sign-up fs-25">PRIJAVI SE</Button>
  //             </Link>
  //           </Col>
  //           <Col xs={3} />
  //         </Row>
  //         <br />
  //         {renderText(
  //           "NAGRADNA IGRA TRAJE OD 14.06. DO 13.07.2024.",
  //           "text-white-small fs-14 lh-0 fw-500"
  //         )}
  //       </div>
  //       <BottomContent />
  //     </div>
  //   </div>
  // );
};

export default Home;
