import React from "react";
import { Col, Row } from "react-bootstrap";

const BottomContent: React.FC = () => {
  return (
    <div className="bottom">
      <img className="bottom-content" src="../bottom-contet.png" alt="bottom" />
    </div>
  );
};

export default BottomContent;
