import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import getWinners from "../helpers/getWinners";
import moment from "moment";
import DatePicker from "./DatePicker";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import getWinnersWeekly from "../helpers/getWinnersWeekly";
import getWinner from "../helpers/getWinner";
import BottomContent from "../Common/BottomContent";
import NavButtons from "../Common/NavButtons";
import Logo from "../Common/Logo";
import NavButtonsMobile from "../Common/NavButtonsMobile";

interface winersDto {
  phone: string;
  award: string;
}

const Winners: React.FC = () => {
  const renderText = (title: string, className?: string) => {
    return (
      <Row>
        <Col>
          <span className={className}>{title}</span>
        </Col>
      </Row>
    );
  };

  const [broj, setBroj] = useState(1);
  const prikaziD = () => {
    setBroj(1);
  };

  const prikaziN = () => {
    setBroj(2);
  };

  const [winners, setWinners] = useState<winersDto[]>([]);
  const [message, setMessage] = useState<string>(
    "Nije pronađen nijedan dobitnik za izabrani datum"
  );
  const [selectedWeek, setSelectedWeek] = useState<number>(1);
  const [date, setDate] = useState<moment.Moment>(moment().subtract(1, "days"));
  const [loader, setLoader] = useState<boolean>(true);
  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    const fetchWinners = async () => {
      let response;
      // const targetDate = new Date("2023-08-26");
      if (broj === 1) {
        response = await getWinners(date.format("YYYYMMDD"));
      } else if (broj === 2) {
        response = await getWinnersWeekly(selectedWeek);
      } else {
        setWinners([]);

        // response = await getWinners(date.format("YYYYMMDD"));
      }

      if (response) {
        setMessage("success");
        setWinners([...response]);
      }
    };

    fetchWinners();
    // const tmp = async () => {
    //   const response = await getWinners(date.format("YYYYMMDD"));
    //   if (response) {
    //     setMessage("success");
    //     setWinners(response);
    //   }
    // };

    // tmp();
  }, [date, selectedWeek, broj]);
  // za nedelju dropdown sa brojevima od 1 do 4
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [winners]);

  const weekOptions = Array.from({ length: 4 }, (_, index) => index + 1);

  return (
    <div className="winners">
      <div className="desktop-content">
        <div className="common-container">
          <Logo />
          <NavButtons />
          <div className="common-center">
            <div className="header-text-winners">
              u fudbalu
              <br />
              uživaj, nagrade
              <br />
              osvajaj!
            </div>
            <div className="tabela">
              <img src="../tabela.png" alt="Tabela" />
              <button className="awards-button" onClick={prikaziD}>
                DNEVNE<br></br>NAGRADE
              </button>
              <button className="awards-button2" onClick={prikaziN}>
                NEDELJNE<br></br>NAGRADE
              </button>
              {broj === 1 && (
                <div className="date-picker-container">
                  <DatePicker setDate={setDate} />
                  <div className="table_page_content">
                    {winners.length === 0 ? (
                      loader ? (
                        <div
                          className="spinner-border text-light"
                          role="status"
                          // style={{ marginTop: "30vh" }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <h3
                          className="text-light"
                          // style={{ marginTop: "25vh" }}
                        >
                          NE POSTOJE DOBITNICI ZA IZABRANI DATUM!
                        </h3>
                      )
                    ) : (
                      <div className="table_div">
                        {/* <MDBTable
                          bordered
                          scrollY
                          maxHeight={
                            width > 700 && height < 700 ? "50vh" : "58.5vh"
                          }
                          className="table"
                        > */}
                        <table className="tabela-dobitnici">
                          {/* <MDBTableHead> */}
                          <tr>
                            <th></th>
                            <th></th>
                          </tr>
                          {/* </MDBTableHead> */}
                          {/* <MDBTableBody> */}
                          {message === "success" &&
                            winners &&
                            winners.map((winner, i) => (
                              <tr key={i}>
                                <td>{i + 1}.</td>
                                <td>{winner.award}</td>
                                <td>{winner.phone}</td>
                              </tr>
                            ))}
                          {/* </MDBTableBody> */}
                          {/* </MDBTable> */}
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {broj === 2 && (
                <div className="week-dropdown-container">
                  <div>
                    <select
                      className="week-dropdown"
                      value={selectedWeek}
                      onChange={(e) => setSelectedWeek(Number(e.target.value))}
                    >
                      {weekOptions.map((week) => (
                        <option key={week} value={week}>
                          {week}. nedelja
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="table_page_content">
                    {winners.length === 0 ? (
                      loader ? (
                        <div
                          className="spinner-border text-light"
                          role="status"
                          // style={{ marginTop: "30vh" }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <h3
                          className="text-light"
                          // style={{ marginTop: "25vh" }}
                        >
                          NE POSTOJE DOBITNICI ZA IZABRANI DATUM!
                        </h3>
                      )
                    ) : (
                      <div className="table_div">
                        {/* <MDBTable
                          bordered
                          scrollY
                          maxHeight={
                            width > 700 && height < 700 ? "50vh" : "58.5vh"
                          }
                          className="table"
                        > */}
                        <table className="tabela-dobitnici">
                          {/* <MDBTableHead> */}
                          <tr>
                            <th></th>
                            <th></th>
                          </tr>
                          {/* </MDBTableHead>
                          <MDBTableBody> */}
                          {message === "success" &&
                            winners &&
                            winners.map((winner, i) => (
                              <tr key={i}>
                                <td>{i + 1}.</td>
                                <td>{winner.award}</td>
                                <td>{winner.phone}</td>
                              </tr>
                            ))}
                          {/* </MDBTableBody>
                        </MDBTable> */}
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <BottomContent /> */}
        </div>
        {/* <BottomContent /> */}
      </div>
      <div>
        <div className="mobile-winners">
          <div className="mobile-content">
            <div className="common-container">
              <div className="header-mobile">
                <NavButtonsMobile />
              </div>
              <div className="common-center">
                <div className="tabela">
                  <img src="../tabela.png" alt="Tabela" />
                  <button className="awards-button" onClick={prikaziD}>
                    DNEVNE<br></br>NAGRADE
                  </button>
                  <button className="awards-button2" onClick={prikaziN}>
                    NEDELJNE<br></br>NAGRADE
                  </button>
                  {broj === 1 && (
                    <div className="date-picker-container">
                      <DatePicker setDate={setDate} />
                      <div className="table_page_content-mobile">
                        {winners.length === 0 ? (
                          loader ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                              // style={{ marginTop: "30vh" }}
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <h3
                              className="text-light"
                              // style={{ marginTop: "25vh" }}
                            >
                              NE POSTOJE DOBITNICI ZA IZABRANI DATUM!
                            </h3>
                          )
                        ) : (
                          <div className="table_div-mobile">
                            {/* <MDBTable
                          bordered
                          scrollY
                          maxHeight={
                            width > 700 && height < 700 ? "50vh" : "58.5vh"
                          }
                          className="table"
                        > */}
                            <table className="tabela-dobitnici-mobile">
                              {/* <MDBTableHead> */}
                              <tr>
                                <th></th>
                                <th></th>
                                {/* <th>TELEFON</th> */}
                              </tr>
                              {/* </MDBTableHead> */}
                              {/* <MDBTableBody> */}
                              {message === "success" &&
                                winners &&
                                winners.map((winner, i) => (
                                  <tr key={i}>
                                    <td>{i + 1}.</td>
                                    <td>{winner.award}</td>
                                    <td>{winner.phone}</td>
                                  </tr>
                                ))}
                              {/* </MDBTableBody> */}
                              {/* </MDBTable> */}
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {broj === 2 && (
                    <div className="week-dropdown-container">
                      <div>
                        <select
                          className="week-dropdown"
                          value={selectedWeek}
                          onChange={(e) =>
                            setSelectedWeek(Number(e.target.value))
                          }
                        >
                          {weekOptions.map((week) => (
                            <option key={week} value={week}>
                              {week}. nedelja
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="table_page_content-mobile">
                        {winners.length === 0 ? (
                          loader ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                              // style={{ marginTop: "30vh" }}
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <h3
                              className="text-light"
                              // style={{ marginTop: "25vh" }}
                            >
                              NE POSTOJE DOBITNICI ZA IZABRANI DATUM!
                            </h3>
                          )
                        ) : (
                          <div className="table_div-mobile">
                            {/* <MDBTable
                              bordered
                              scrollY
                              maxHeight={
                                width > 700 && height < 700 ? "50vh" : "58.5vh"
                              }
                              className="table"
                            > */}
                            <table className="tabela-dobitnici-mobile">
                              {/* <MDBTableHead> */}
                              <tr>
                                <th></th>
                                <th></th>
                              </tr>
                              {/* </MDBTableHead>
                              <MDBTableBody> */}
                              {message === "success" &&
                                winners &&
                                winners.map((winner, i) => (
                                  <tr key={i}>
                                    <td>{i + 1}.</td>
                                    <td>{winner.award}</td>
                                    <td>{winner.phone}</td>
                                  </tr>
                                ))}
                              {/* </MDBTableBody>
                            </MDBTable> */}
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // return (
  //   <div>
  //     <img className="tekst-uzivaj" src="../dobitnici-uzivaj.png" alt="Enjoy" />
  //     <img className="tabela" src="../tabela.png" alt="Tabela" />
  //   </div>
  // );
};

export default Winners;
