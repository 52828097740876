import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const NavButtons: React.FC = () => {
  const location = useLocation();

  const renderNavButton = (to: string, title: string) => {
    const isActive = location.pathname === to;
    return (
      <Col
        md={2}
        className={`nav-button-desktop border-right ${
          isActive ? "active-link" : ""
        }`}
      >
        <Link to={to}>{title}</Link>
      </Col>
    );
  };

  return (
    <Row className="nav-buttons">
      {renderNavButton("/", "PRIJAVI SE")}
      {renderNavButton("/", "MEHANIZAM")}
      {renderNavButton("/nagrade", "NAGRADE")}
      {renderNavButton("/dobitnici", "DOBITNICI")}
      {renderNavButton("/pravila", "PRAVILA")}
      {renderNavButton("/kontakt", "KONTAKT")}
    </Row>
  );
};

export default NavButtons;
